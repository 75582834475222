
import { defineComponent } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import type { BreadcrumbsLink, DetailsTableItemType } from '@/definitions/shared/types'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  name: 'ServicesCarrierLookupDetails',
  components: {
    TmStatus,
    DetailsTable,
    TmButton,
    PageBlock,
  },
  setup() {
    const breadcrumbs: BreadcrumbsLink[] = [
      { label: 'Services', name: 'base.services.countries.index' },
      { label: 'Carrier lookup', name: 'base.services.carrierLookup.index' },
      { label: 'Bulk lookup', name: 'base.services.carrierLookup.bulkLookup' },
      { label: 'Contacts migration list 2021.xlsx' },
    ]
    const tableItems: DetailsTableItemType[] = [
      { label: 'File name', value: 'Contacts migration list 2021.xlsx' },
      { label: 'File size', value: '0.1 kB' },
      { label: 'Total rows in file', value: '2' },
      { label: 'Total cost', value: '$0.08' },
      {
        label: 'Status',
        value: {
          name: 'completed',
          outline: true,
          color: 'green',
        },
      },
    ]

    return {
      breadcrumbs,
      tableItems,
    }
  },
})
